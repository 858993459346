// src/App.js

import React from 'react';
import './App.css';
import logoName from './assets/droneopz-name.png'; // Ensure the image is placed in the correct directory

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="video-container">
          <video autoPlay muted playsInline loop className="Background-video">
            <source src={`${process.env.PUBLIC_URL}/background-video.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </header>
      <main className="App-content">
        <img src={logoName} alt="DroneOpz" className="logo-name" />
        <p className="coming-soon">Coming Soon</p>
        <p>
          DroneOpz is your one-stop platform for hiring professional drone pilots and learning about drones.
          Stay tuned for a seamless experience to connect with top drone pilots and enhance your drone skills
          through our comprehensive courses and certifications.
        </p>
      </main>
    </div>
  );
}

export default App;
